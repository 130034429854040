<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    
                        <v-select
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="sites"
                            label="site_name"
                            class="w-100"
                            placeholder="Project Site"
                            v-model="siteData"
                            @input="changeSite"
                            :clearable="false"
                        >
                          <template #selected-option="{ site_name }">
                            <span v-if="site_name.length < 23">{{site_name}}</span>
                            <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                          </template>
                        </v-select>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    
                        <v-select
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="buildings"
                            label="name"
                            class="w-100"
                            placeholder="Building Name"
                            v-model="buildingData"
                            @input="filterTable"
                            :clearable="false"
                        >
                          <template #selected-option="{ name }">
                            <span v-if="name.length < 23">{{name}}</span>
                            <span v-if="name.length >= 23">{{name.substring(0, 23) + '...'}}</span>
                          </template>
                        </v-select>
                    </b-col>

                    <b-col cols="12" md="6" class="mb-md-0 mb-2">
                    
                        <v-select
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="form_types"
                            class="w-100"
                            placeholder="Select Form"
                            v-model="form_name"
                            @input="filterTable"
                            :clearable="false"
                        >
                          
                        </v-select>
                    </b-col>

                </b-row>
            </b-card-body>
        </b-card>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">

            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            @input="filterTable"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="9">
                        <div class="d-flex align-items-center justify-content-end mobile-view">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input"
                                placeholder="Search..."
                                @input="filterTable"
                            />
                            <b-button class="mr-1 mobile-margin-bottom" variant="warning" :to="setLocationRoute()">
                                <span class="text-nowrap">Add Location</span>
                            </b-button>
                            <b-button 
                                :disabled="selected.length > 0 ? false : true"
                                variant="primary"
                                class="mr-1 mobile-margin-bottom"
                                @click="openAssignSiteModel"
                            >
                                <span class="text-nowrap">Assign Form</span>
                            </b-button>

                            

                            <b-button
                                :disabled="selected.length > 0 ? false : true"
                                variant="danger" 
                                class="mr-1 mobile-margin-bottom" 
                                @click="changeMultipleStatus('archive')"
                            >
                                <span class="text-nowrap">Delete</span>
                            </b-button>

                            <b-button 
                                variant="info"
                                class="mr-1 mobile-margin-bottom"
                                @click="openImportLocationModel"
                            >
                                <span class="text-nowrap">Import</span>
                            </b-button>
                        </div>
                    </b-col>

                </b-row>
            </div>

            <b-table sticky-header
                responsive
                show-empty
                ref = "refUserListTable"
                class = "position-relative midTable common_big_tbl"
                primary-key = "id"
                empty-text = "No matching records found"
                :fields = "tableColumns"
                :sort-by.sync = "sortBy"
                :items = "dataList"
                :sort-desc.sync = "isSortDirDesc"
                :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
            >
                <template #head(checkbox)="items">
                    <b-form-checkbox class="custom-control-warning" v-model="selectAll" @change="selectall"/>
                </template>

                 <template #cell(info)="items">             
                    <feather-icon
                    :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
                    @click="items.toggleDetails"
                    />
                </template>

                <template #cell(checkbox)="items">
                    <b-form-checkbox v-model="selected" @change="selectAll = false" :value="items.item._id" class="custom-control-warning" />
                </template>

                <template #cell(site)="items">
                    <p class="wordBreak"  @click="items.toggleDetails">{{ items.item.site.site_name | capitalize}}</p>
                </template>

                <template #cell(building)="items">
                    <p class="wordBreak"  @click="items.toggleDetails">{{ items.item.building.name | capitalize}}</p>
                </template>    
                
                <template #cell(name)="items">
                    <p class="wordBreak"  @click="items.toggleDetails">{{ items.item.name | capitalize}}</p>
                </template>

                <!-- <template #cell(level)="items">
                    <p class="wordBreak">{{ items.item.level | capitalize}}</p>
                </template>     -->

                <template #cell(location_type)="items">
                    <p class="wordBreak"  @click="items.toggleDetails">{{ items.item.location_type.join(', ')}}</p>
                </template>          

                <template #cell(form_type)="items">
                    <!-- <p class="wordBreak">{{ items.item.form_type.join(', ')}}</p> -->
                    <span  @click="items.toggleDetails" v-b-tooltip.hover.v-primary.top :title="items.item.form_type.join(', ')" v-if="items.item.form_type.length > 3">
                        {{getFormName(items.item.form_type)[0]}} 
                        <b-badge v-if="getFormName(items.item.form_type)[1] != ''" pill variant="primary"> +{{ getFormName(items.item.form_type)[1]}} </b-badge>
                    </span>
                    <span  @click="items.toggleDetails" v-if="items.item.form_type.length <= 3">
                        {{ items.item.form_type.join(', ')}} 
                    </span>
                </template>            
                
                <template #cell(status)="items">
                    <b-badge pill :variant="variantColor(items.item.status)" class="text-capitalize"  @click="items.toggleDetails">
                        {{ items.item.status}}
                    </b-badge>
                </template>

                <!-- <template #cell(created_at)="items">
                    <p class="wordBreak">{{sitedateTime(items.item.created_at,siteData.pdf_date_format,siteData.pdf_time_format)}}</p>
                </template> -->

                <template #cell(actions)="items">
                    <!-- <b-dropdown
                        
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    > -->
                        <!-- 3 dot menu -->
                        <!-- <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template> -->

                        <!-- <b-dropdown-item  :to="{ name: 'form-areas', 
                        params: { site: items.item.site._id,build:items.item.building._id,loc_id: items.item._id,name:items.item.name } }">
                            <feather-icon icon="EyeIcon" />
                            <span class="align-middle ml-50">Area</span>
                        </b-dropdown-item> -->

                        <!-- Edit button -->
                        <!-- <b-dropdown-item  :to="{ name: 'edit-hand-sanitizer-locations', params: { id: items.item._id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item> -->

                        <!-- Change Status button -->
                        <!-- <b-dropdown-item  @click="statusChange(items.item)">
                            <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
                            <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
                            <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
                        </b-dropdown-item> -->

                        <!-- Delete button -->
                        <!-- <b-dropdown-item  @click="deleteItem(items.item._id)">
                            <feather-icon icon="Trash2Icon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item> -->

                    <!-- </b-dropdown>  -->

                         <b-link :to="{ name: 'edit-hand-sanitizer-locations', params: { id: items.item._id } }"
                                v-b-tooltip.hover.v-warning
                                title="Edit"
                                variant="outline-warning"
                            >
                                <feather-icon icon="EditIcon" class="mediumSize mr-1"/>
                                
                            </b-link>
                            
                      <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" @click="statusChange(items.item)">
                        <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
                    </b-link>
                     <b-link @click="deleteItem(items.item._id)"
                        v-b-tooltip.hover.v-warning
                        title="Delete"
                        variant="outline-warning"
                    >
                        <feather-icon icon="Trash2Icon" class="mediumSize mr-1"/>
                        
                    </b-link>
                </template>

                  <template #row-details="items">
                    <div class="p-1 px-2 fbDetail mb-1">
                        <b-row>

                        <b-col cols="12" xl="12" >
                            <table class="mt-2 mt-xl-0 w-100">
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 10%">
                                        <div class="d-flex align-items-center">
                                
                                        <div class="ml-1">
                                            <h6 class="mb-0 mediumFonts">
                                                Level
                                            </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                        </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                      <span class="wordBreak">{{ items.item.level | capitalize}}</span>
                                    </td>

                                    <th class="pb-50" style="width: 5%">
                                        <div class="d-flex align-items-center">
                                
                                        <div class="ml-1">
                                            <h6 class="mb-0 mediumFonts">
                                                Added On
                                            </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                        </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 10%;padding-top: 8px;">
                                        <span class="wordBreak">{{sitedateTime(items.item.created_at,siteData.pdf_date_format,siteData.pdf_time_format)}}</span>
                                    </td>


                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                    <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-secondary"
                                        @click="items.toggleDetails"
                                        size="sm"
                                        class="ml-1"
                                        >
                                        Hide Detail
                                        </b-button>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                </tr>
                            </table>
                        </b-col>

                        </b-row>
                    </div>
                 </template>
            </b-table>

            <div class="mx-2 mb-2">
                <b-row>

                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                    </b-col>

                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="filterTable"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>

                </b-row>
            </div>

        </b-card>

        <b-modal 
            id="pass"
            ref="pass"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            title="Verify Password"
            @ok="verifyPassword"
            no-close-on-backdrop
        >
            <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>

            <b-form>
                <b-row>
                    <b-col md="12">
                        <b-form-group label="Password" class="required">
                            <b-form-input placeholder="" v-model="password"/>
                        </b-form-group>
                    </b-col>
                </b-row>      
            </b-form>

        </b-modal>

        <!-- Assign To Project Site Model -->
        <b-modal
            class="assignCF"
            id="assign_form"
            ref="assign_form"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            title="Assign Form"
            size="lg"
            @ok="assignForm"
            no-close-on-backdrop
        >

            <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body">
                    {{error_message}}
                </div>
            </b-alert>

            <b-form>
                <b-row>
                    <b-col cols="12" md="12" class="mb-md-0 mb-2">
                        <b-form-group label="Forms" class="required">
                            
                            <v-select
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="assign_form_options"
                                multiple
                                class="w-100"
                                placeholder="Select Form"
                                v-model="assign_form"
                                @input="updateValues();"
                                :selectable="option => !option.disabled"
                                :close-on-select=false
                            :clear-on-select=false
                            >
                            </v-select>
                        </b-form-group>
                    </b-col>
                </b-row>

                <!-- <b-row v-if="req_code2">
                    <b-col md="12">
                        <b-form-group label="Code" :class="req_code2 ? 'required': ''" class="mt-1">
                            <b-form-input autocomplete="off" placeholder="3 to 50 characters" v-model="form.unique_id" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row v-if="req_code2">
                    <b-col md="12">
                        <b-form-group label="No. of Area" :class="req_code2 ? 'required': ''" class="mt-1">
                            <b-form-input autocomplete="off" placeholder="1-20" v-model="form.num_area" />
                        </b-form-group>
                    </b-col>
                </b-row> -->

                <b-row>
                    <b-col md="12" v-if="checkForm()">
                        <b-form-group label="Toilet Type" class="required mt-1">
                            <v-select
                                v-model="form.type"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                multiple
                                :options="location_types"
                                placeholder="Select"
                                @input="sortLocType"
                                @option:selected="addLocationType($event)"
                                @option:deselected="removeLocationType($event)"
                                :close-on-select=false
                            :clear-on-select=false
                            />
                        </b-form-group>
                    </b-col>

                </b-row>

                <b-row>
                    <b-col cols="12" v-if="req_code2 && form.type.length > 0">
                        <b-row class="mt-2">
                            <b-col cols="4">
                                <h5>Toilet Name <span class="text-danger">*</span></h5>
                            </b-col>
                            <b-col cols="4">
                                <h5>Code <span class="text-danger">*</span></h5>
                            </b-col>
                            <b-col cols="4">
                                <h5>No. of Area <span class="text-danger">*</span></h5>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1" v-for="(ltd, ind) in form.location_type_detail" :key="ind">
                            <b-col cols="4">{{ltd.name}}</b-col>
                            <b-col cols="4">
                                <b-form-input autocomplete="off" placeholder="3 to 50 characters" v-model="ltd.unique_id" :key="ind"/>
                            </b-col>
                            <b-col cols="4">
                                <b-form-input autocomplete="off" placeholder="1-20" v-model="ltd.num_area" :key="ind"/>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

            </b-form>
        </b-modal>

        <!-- Import Location -->

        <b-modal
            id="import_loc"
            ref="import_loc"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            title="Import Location"
            @ok="importLocation"
            size="lg"
            no-close-on-backdrop
        >

            <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body">
                    {{error_message}}
                </div>
            </b-alert>

            <b-form>
                <b-row >
                    <b-col
                     cols="12"
                     md="12"
                    >
                      <div class="d-flex align-items-center justify-content-end">
                        
                        <b-button
                          variant="danger"
                          align="right"
                          @click="sampleDownload"
                        >
                          <span class="text-nowrap">Download Sample</span>
                        </b-button>
                      </div>
                    </b-col>
                </b-row>
                
                <b-row >
                    
                    <b-col md="12">
                        <b-form-group
                            label="Project Site"
                            class="required"
                            
                          >
                      
                            <v-select
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="filterSites()"
                                label="site_name"
                                class="w-100"
                                placeholder="Select"
                                v-model="imp_site"
                                @input="impLocBuildings()"
                                :clearable="false"
                            >
                              <template #selected-option="{ site_name }">
                                <span v-if="site_name.length < 23">{{site_name}}</span>
                                <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                              </template>
                            </v-select>
                        </b-form-group>

                      
                    </b-col>
                </b-row>

                <b-row >   
                    <b-col md="12">

                        <b-form-group
                            label="Building"
                            class="required"
                            
                          >
                    
                            <v-select
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="imp_buildings"
                                label="name"
                                class="w-100"
                                placeholder="Select"
                                v-model="imp_building"
                                :clearable="false"
                            >
                              <template #selected-option="{ name }">
                                <span v-if="name.length < 23">{{name}}</span>
                                <span v-if="name.length >= 23">{{name.substring(0, 23) + '...'}}</span>
                              </template>
                            </v-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                
                <b-row >

                    <b-col md="12">
                      <b-form-group
                        label="Upload File Template"
                        label-for="upload_file"
                        class="required"
                      >
                        <b-form-file
                          id="upload_file"
                          ref="uploadFile"
                          accept=".xls, .xlsx, .csv"
                          @change="onFileChange"
                          
                          
                        />
                        <small class="text-danger">supported format: .xls or .xlsx</small>
                        </b-form-group>
                    </b-col>
                </b-row>
         
                </b-form>
        
        </b-modal>

    </div>
</template>

<script>
    import {
        BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm,BBreadcrumb,BFormFile,VBTooltip
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import { ref, onUnmounted } from '@vue/composition-api'
    import { POST_API } from "../../../store/actions.type"

    export default {
        components: {
            BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm,BBreadcrumb,BFormFile
        },
        directives: {
            'b-tooltip': VBTooltip
        },

        data() {
            return {
                webUrl: process.env.VUE_APP_SERVER_URL,
                error_message: null, 
                showDismissibleAlert:false,

                tableColumns: [
                    { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '1%'}},
                    { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '1%' } },
                    { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '10%'}},
                    { key: 'building', label: 'Building Name', sortable: false , thStyle:  {width: '10%'}},
                    // { key: 'level', label: 'Level', sortable: true , thStyle:  {width: '15%'}},
                    { key: 'name', label: 'Location Name', sortable: true , thStyle:  {width: '12%'}},
                    { key: 'form_type', label: 'Form Type', sortable: false , thStyle:  {width: '25%'}},
                    { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '8%'}},
                    // { key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '20%'}},
                    { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '18%'}},
                ],
                
                sites:[],
                buildings:[],
                siteData:null,  
                buildingData:null,  

                // b-table and pagination options
                items           : [],
                isSortDirDesc   : true,
                perPageOptions  : [10, 20, 50, 100],
                perPage         : 10,
                sortBy          : 'id',
                totalRecords    : 0,
                currentPage     : 1,
                searchQuery     : '',
                from            : null,
                to              : null, 

                // multicheckbox vars
                selectAll : false,
                selected  : [],
                delType   : '',
                delStatus : '',
                password  : '',

                // Assign To Project Site vars
                addSites : [],
                site_id  : '',
                popshowDismissibleAlert:false,
                form_types:[],
                form_name:null,
                assign_form:[],
                //Import Location
                imp_site:null,
                imp_building:null,
                imp_buildings:[],
                selectedFile:{
                    file:null,
                    name:'',
                },

                form: {
                    type:[],
                    unique_id:null,
                    num_area:0,
                    location_type_detail:[]
                },
                location_types:['Male','Female','Handicap','Other'],
                req_code:false,
                req_code2: false,
                assign_form_options:[],
            }
        },

        watch: { 
            'assign_form': function(newVal, oldVal) { // watch it
                var assign_form = newVal.map(item => { return item.label;})
                if(assign_form.includes('Flash Times')){
                    this.req_code2 = true;
                } 
                else if(assign_form.includes('Soap Dispenser') || assign_form.includes('Floor Traps') || assign_form.includes('Air Freshener') || assign_form.includes('Toilet Seat Sanitizer')){
                    this.req_code2 = false;
                }
                else {
                    this.req_code2 = false;
                }
            }
        },

        methods : {
            updateValues(){
                // if (!this.assign_form.includes('Flash Times')) {
                //     this.form = {
                //         type:[],
                //         unique_id:null,
                //         num_area:0,
                //     };
                // } else if(!this.assign_form.includes('Soap Dispenser') || !this.assign_form.includes('Floor Traps') || !this.assign_form.includes('Air Freshener') || !this.assign_form.includes('Toilet Seat Sanitizer')) {
                //     this.form = {
                //         unique_id:null,
                //         num_area:0,
                //     };
                // }
                var assign_form = this.assign_form.map(item => { return item.label;});

                if(!assign_form.includes('Flash Times') && !assign_form.includes('Soap Dispenser') && !assign_form.includes('Floor Traps') && !assign_form.includes('Air Freshener') && !assign_form.includes('Toilet Seat Sanitizer')){
                    this.form.type      = [];
                    this.form.unique_id = null;
                    this.form.num_area  = 0;
                } else if(!assign_form.includes('Flash Times')){
                    this.form.unique_id = null;
                    this.form.num_area  = 0;
                }
            },
            checkForm(){
                var assign_form = this.assign_form.map(item => { return item.label;});

                if (assign_form.indexOf('Flash Times') >=0 || assign_form.indexOf('Soap Dispenser') >=0 || assign_form.indexOf('Floor Traps') >=0 || assign_form.indexOf('Air Freshener') >=0 || assign_form.indexOf('Toilet Seat Sanitizer') >=0) {
                    this.req_code = true;
                }else{
                    this.req_code = false;
                }
                return this.req_code;
            },

            dataList(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        page          : this.currentPage,
                        keyword       : this.searchQuery,
                        rowsPerPage   : this.perPage,
                        sortBy        : this.sortBy,
                        sortDirection : this.isSortDirDesc,

                        role          : this.$store.getters.currentUser.role,
                        om_sites      : this.$store.getters.currentUser.om_sites,
                        site          : this.siteData ? this.siteData._id :null,
                        building      : this.buildingData ? this.buildingData._id :null,
                        form_name     : this.form_name,
                        form_types    : this.form_types,
                    },
                    api: '/api/hand-sanitizer-location-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items          = this.$store.getters.getResults.data.docs;
                        this.totalRecords   = this.$store.getters.getResults.data.total;
                        this.perPage        = this.$store.getters.getResults.data.limit;
                        this.from           = this.$store.getters.getResults.data.from
                        this.to             = this.$store.getters.getResults.data.to

                        return this.items;
                    }
                });
            },

            filterTable(){
                this.$refs.refUserListTable.refresh();
            },

            variantColor(status){
                if (status == 'active') {
                    return 'success';
                }else{
                    return 'primary';
                }
            },

            statusChange(item){
                if (item.status == 'active') {
                    var msg     = 'Are you sure want to inactivate this record?';
                } else{
                    var msg     = 'Are you sure want to activate this record?';
                }

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : item._id,
                                status : item.status
                            },
                            api : "/api/change-hand-sanitizer-location-status",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;
                                this.showAlert();
                            } else {
                                this.successAlert();
                                this.$refs.refUserListTable.refresh();
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },

            deleteItem(id){
                var msg = 'Are you sure want to delete this record?';

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : id,
                                status : 'deleted'
                            },
                            api : "/api/change-hand-sanitizer-location-status",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                this.successAlert();

                                this.$refs.refUserListTable.refresh();
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },

            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        //om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.sites    = this.$store.getters.getResults.data;
                        this.addSites = JSON.parse(JSON.stringify(this.$store.getters.getResults.data));

                        if (this.sites.length == 1 ) {
                            this.siteData = this.sites[0];
                        }

                        var obj = {
                            _id:'all-site',
                            site_name:'All Project Sites',
                            pdf_date_format: 'DD MMM YYYY',
                            pdf_time_format: 'HH:mm'
                        }
                        this.sites.unshift(obj);

                        var site_id = this.$route.params.site_id;

                        this.sites.forEach(item => {
                            if (item._id == site_id) {
                                this.siteData = item;
                            }
                        })
                        
                        this.getBuildings(this.$route.params.id);

                        return this.sites;
                    }
                });
            },
            // multicheckbox functions
            selectall(e){
                this.selected = [];
                if (e) {
                    var selected = [];

                    this.items.forEach(function(item){
                        selected.push(item._id);
                    })

                    this.selected = selected;
                }
            },

            changeMultipleStatus(status){
                this.password = '';

                this.delType = 'multiple';
                this.delStatus = status;

                if (this.selected.length > 0) {
                    var msg = '';
                    var msg2 = '';

                    if (status == 'archive') {
                        msg = 'Are you sure you want to delete selected record?';
                        msg2 = 'Records Deleted Successfully.';
                    }if (status == 'active') {
                        msg = 'Are you sure you want to Activate selected record?';
                        msg2 = 'Records Activated Successfully.';
                    }if (status == 'inactive') {
                        msg = 'Are you sure you want to inactive selected record?';
                        msg2 = 'Records Inactivated Successfully.';
                    }

                    Swal.fire({
                        title: 'Please Confirm',
                        text: msg,
                        icon: 'warning',
                        position: 'top-center',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                    })
                    .then(result => {
                        if (result.value) {
                            //this.$refs['pass'].show();
                            this.deleteMultiQuestion();

                        }
                    })
                    .catch(err => {
                        // An error occurred
                    })   
                }else{
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Please select Location(s).',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            },

            verifyPassword(e){
                e.preventDefault();

                return this.$store.dispatch(POST_API, {
                    data:{
                        user_id: this.$store.getters.currentUser._id,
                        password:this.password
                    },
                    api:"/api/verify-password",
                    })
                    .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                    } else {
                        this.deleteMultiQuestion();
                        this.$refs['pass'].hide();
                    }
                });
            },

            deleteMultiQuestion(){
                var message='Project Site Deleted Successfully.';
                
                return this.$store.dispatch(POST_API, {
                    data:{
                        id     : this.selected,
                        status :'deleted'
                        },
                    api:"/api/change-hand-sanitizer-status-multi",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showAlert();
                    } else {
                        this.successAlert();
                        this.$refs.refUserListTable.refresh();
                        this.selectAll = false;
                    }
                });
            },

            // Assign To Project Site functions
            openAssignSiteModel(){
                this.popshowDismissibleAlert = false;
                this.assign_form = [];
                this.form.unique_id = '';
                this.form.num_area = '';
                this.form.type = [];
                this.form.location_type_detail = [];

                var selectedLoc = this.items.filter(item => { return this.selected.indexOf(item._id) >= 0 ;})
                selectedLoc = selectedLoc.map(item => { return item.form_type});

                var common = selectedLoc.shift().filter(function(v) {
                    return selectedLoc.every(function(a) {
                        return a.indexOf(v) !== -1;
                    });
                });
                this.assign_form_options = [];
                //this.assign_form_options = common;
                this.form_types.forEach(item => {

                    var obj = {};
                    obj.label = item;
                    if(common.indexOf(item) < 0){
                        obj.disabled = false;
                    }else{
                        obj.disabled = true;

                    }
                    this.assign_form_options.push(obj);
                })


                console.log(this.assign_form_options,'aya');

                this.$refs['assign_form'].show();
            },

            /*updateSite(bvModalEvt){
                bvModalEvt.preventDefault()
                if (this.selected.length > 0) {
                    return this.$store.dispatch(POST_API, {
                        data:{
                            site : this.site_id ? this.site_id._id : null,
                            id : this.selected.reverse(),
                        },
                        api : '/api/add-site-hand-sanitizer-location'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.popshowDismissibleAlert = true;
                        } else {
                            this.popshowDismissibleAlert = false;
                            var message  = this.$store.getters.getResults.message;
                            this.successAlert()
                            .then((result) => {
                                this.filterTable();
                                this.$refs['assign_site'].hide();
                                this.selected = [];
                                this.site_id  = '';
                                this.selectAll = false;
                            });
                        }
                    });
                }else{
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Please select Location(s).',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            },*/

            assignForm(e){
                e.preventDefault()
                if (this.selected.length > 0) {
                    
                    var assign_form = this.assign_form.map(item => { return item.label;});

                    return this.$store.dispatch(POST_API, {
                        data:{
                            assignForm : assign_form,
                            ids : this.selected.reverse(),
                            items : this.form,
                            req_code : this.req_code,
                            req_code2:this.req_code2
                        },
                        api : '/api/assign-forms-to-locations'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.popshowDismissibleAlert = true;
                        } else {
                            this.popshowDismissibleAlert = false;
                            var message  = this.$store.getters.getResults.message;
                            this.successAlert()
                            .then((result) => {
                                this.$refs['assign_form'].hide();
                                this.selected = [];
                                this.site_id  = '';
                                this.selectAll = false;
                                this.form.unique_id = '';
                                this.form.num_area = '';
                                this.form.type = [];
                                this.filterTable();
                            });
                        }
                    });
                }else{
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Please select Location(s).',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            },

            
            changeSite(){

                this.buildings = [];
                this.buildingData = null;

                if (this.siteData == null){
                    this.buildings = [];
                }else{
                    this.getBuildings('');
                }
            },

            getBuildings(building_id){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        site:this.siteData ? this.siteData._id : null
                    },
                    api: '/api/all-buildings'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.buildings    = this.$store.getters.getResults.data;
                        if (this.buildings.length == 1 ) {
                            this.buildingData = this.buildings[0];
                            this.filterTable();
                        }

                        if (building_id != '') {

                            var id = this.$route.params.id;

                            this.buildings.forEach(item => {
                                if (item._id == id) {
                                    this.buildingData = item;
                                }
                            })
                        }

                        if (this.buildings.length > 0) {

                            var obj = {
                                _id:'all-building',
                                name:'All Buildings'
                            }
                            this.buildings.unshift(obj);    
                        }

                        
                        this.filterTable();
                        return this.buildings;
                    }
                });
            },
            setLocationRoute(){
                var form_name = null;

                form_name = this.form_name ? this.form_name.replace(/ /g,"_") : null;


                if (this.siteData != null && this.buildingData != null) {
                    
                    var url = {name:'add-hand-sanitizer-locations',params:{site:this.siteData._id,building:this.buildingData._id,form_name:form_name}};    
                
                }else if(this.siteData != null && this.buildingData == null){
                    var url = {name:'add-hand-sanitizer-locations',params:{site:this.siteData._id,building:null,form_name:form_name}}
                }else{
                    var url = {name:'add-hand-sanitizer-locations'};
                }
                
                return url;
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:{name:'custom-forms'},
                text: 'Custom Forms',
              },{
                to:{name:'custom-form-settings'},
                text:'Form Settings',
              },
              {
                to: {name:'hand-sanitizer-buildings'}, // hyperlink
                text: 'Buildings', // crumb text
                
              },{
                to: null, // hyperlink
                text: 'Locations', // crumb text
                
              }];
              return item;
            },
            getForms(){
              return this.$store.dispatch(POST_API, {
                 data:{
                   id: this.$store.getters.currentUser.organization,
                   role: this.$store.getters.currentUser.role,
                 },
                 api: '/api/get-custom-forms-by-role'
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                  } else {
                      var data = this.$store.getters.getResults.data.custom_forms;
                      //console.log(this.formsList);
                      if (this.$store.getters.currentUser.role != 'admin') {
                          data.sort((a, b) => (a.form.title > b.form.title ? 1 : -1))
                      }
                      var form_types = [];

                      if (this.$store.getters.currentUser.role != 'admin') {
                        form_types = data.map(item => { return item.form.title});

                        form_types.sort((a,b) => (a > b ? 1 : -1 ));

                      }else{
                        form_types = data.map(item => { return item.title});
                      }

                      var arr = ['Conservancy Supervisor Daily Report','Conservancy Supervisor Daily Report on Public Toilets','Daily Supervisor Checklist','Mosquito Prevention Report','Lactation Checklist','Monthly Inspection','Safety Inspection','Toolbox Meeting','Stock Inventory Form'/* , 'Toilet Inspection Checklist' */]

                      var arr1 = []
                      form_types.forEach(function(item){

                         if (arr.indexOf(item) < 0) {
                            arr1.push(item);
                         }
                      })
                      this.form_types = arr1;
                      
                      this.filterTable();
                      return this.form_types;
                      
                  }
              });
            },
            checkFormRoute(){
                if (this.$route.params.form) {
                    var form = this.$route.params.form;
                    form = form.replace(/_/g," ");
                    //console.log(form);
                    this.form_name = form;
                    this.filterTable();

                }else{
                    /*console.log('nhi hai');*/
                }
            },
            openImportLocationModel(){
                
                this.popshowDismissibleAlert = false;
                this.imp_site = '';
                this.imp_building = null;
                this.imp_buildings = [];
                this.selectedFile = {
                    file:null,
                    name:'',
                }
                this.$refs['import_loc'].show();

            },
            impLocBuildings(){
                this.imp_building = null;
                this.imp_buildings= [];
                
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        site:this.imp_site ? this.imp_site._id : null
                    },
                    api: '/api/all-buildings'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.imp_buildings    = this.$store.getters.getResults.data;
                        return this.imp_buildings;
                    }
                });
            },
            filterSites(){
                var arr = [];
                this.sites.forEach(item => {
                    if (item._id != 'all-site') {
                        arr.push(item);
                    }
                })

                return arr;
            },
            sampleDownload(){
                var url = process.env.VUE_APP_SERVER_URL + '/samplefiles/building_locations.xlsx';
                window.open(url, '_blank');
            },
            onFileChange(event) {
            
                this.selectedFile.file = null;
                this.selectedFile.name = '';
                var input = event.target;
                var form = {
                    file:null
                };

                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.readAsDataURL(input.files[0]);
                    form.file = input.files[0];
                    

                    if ((form.file.type != 'application/vnd.ms-excel') && form.file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                        
                        this.selectedFile.file = null;
                        this.selectedFile.name = '';
                        
                        form.file="";

                        this.popshowDismissibleAlert=false;

                        this.$refs.uploadFile.reset();

                        Swal.fire({
                          icon: 'error',
                          title: "Please upload .xls or .xlsx files",
                        })
                    } else if (form.file.size > 2097152) {

                        form.file="";
                        this.selectedFile.file = null;
                        this.selectedFile.name = '';
                        this.popshowDismissibleAlert=false;
                        this.$refs.uploadFile.reset();
                        //this.bulkFile = null;

                        Swal.fire({
                          icon: 'error',
                          title: "Maximum 20 MB files are allowed to upload"
                        })
                    } else {
                        reader.onload = (e) => {
                            
                            this.selectedFile.file = e.target.result;
                            this.selectedFile.name = form.file.name;

                            this.popshowDismissibleAlert=false;
                        }
                    }
                }
            },
            importLocation(e){
                e.preventDefault();
                return this.$store.dispatch(POST_API, {
                   data:{
                     site:this.imp_site,
                     building:this.imp_building,
                     selectedfile:this.selectedFile,
                     form_types:this.form_types
                     
                   },
                   api: '/api/import-building-locations'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.popshowDismissibleAlert = true;
                        this.$refs.uploadFile.reset();
                        this.selectedFile = {
                            file:null,
                            name:'',
                        }

                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        
                        Swal.fire({
                          position: 'center',
                          icon: 'success',
                          title: 'Locations Imported Successfully.',
                          showConfirmButton: false,
                          timer: 1500
                        }).then((result) => {
                            this.$refs['import_loc'].hide();
                            this.filterTable();
                        });
                        
                    }
                });
            },
            sortLocType(e){
                const map = {'Male':1,'Female':2,'Handicap':3,'Other':4};

                this.form.type.sort((a, b) => {
                    return map[a] - map[b];
                });
            },
            addLocationType(e){
                var temp = {
                    name      : e[e.length-1],
                    unique_id : '',
                    num_area  : 0
                };
                this.form.location_type_detail.push(temp);

                const map = {'Male':1,'Female':2,'Handicap':3,'Other':4};

                this.form.location_type_detail.sort((a, b) => {
                    return map[a.name] - map[b.name];
                });
            },
            removeLocationType(e){
                var temp = this.form.location_type_detail;
                for(var i = 0; i < temp.length; i++){
                    if(this.form.location_type_detail[i].name == e){
                        this.form.location_type_detail.splice(i,1)
                    }
                }
            },
            getFormName(item){
                if(item.length > 3){
                    return [item[0] + ', ' + item[1] + ', ' + item[2], (item.length - 3)]
                } else {
                    return [item.join(', '), ''];
                }
            }

        },
        mounted(){
            this.allSites()
            this.getForms();
            this.checkFormRoute();
            
        }
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
